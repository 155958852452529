import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {RiderInfo, CompleteUserData, UserIdData, SleepApneaLevel, InvestmentGoal} from '@amplify/athena-models';
import {parseHeightStringToInches} from '../helpers/parsers';
import UserData from './UserData';
import {anyCond, diagCond} from './UserData/types';
import {BankruptcyChapter} from '@amplify/athena-models/dist/recommendation/bankruptcyChapter';
import {MedCount} from '@amplify/athena-models/dist/recommendation/medCount';
import merge from 'lodash.merge';
dayjs.extend(customParseFormat);

/**
 * Contains functions with no side effects used by CalculatorStore component
 */

export const mapStateToEligibleProductsRequestData = (
    userData: UserData,
): {userData: CompleteUserData; userIdData: UserIdData} => {
    const {phase1, phase2, utm, partners} = userData;
    const dateOfBirth = dayjs(phase1.dateOfBirth, 'MM/DD/YYYY');
    const heightInches = phase2.height ? parseHeightStringToInches(phase2.height) : 173;

    const hasCondition = (condition: string) => !!userData.phase2.diagCond.find((c) => c.label === condition)?.value;
    const hasBehavior = (behavior: string) => !!userData.phase2.behaviors.find((c) => c.label === behavior)?.value;

    const diabetes = hasCondition(diagCond.Diabetes);
    let diabetesType: 'type1' | 'type2' | null = null;
    if (diabetes) {
        diabetesType = phase2.diagDiabetesType ? 'type1' : 'type2';
    }

    let completeUserData: CompleteUserData = {
        stateId: phase1.zipCodeId,
        zip: phase1.zipCode,
        dateOfBirth: dateOfBirth?.toISOString(),
        usedTobacco: phase2.usedTobacco,
        tobaccoLastUseDate: getStandardizedDateString(phase2.usedTobaccoLastDate),
        gender: phase1.gender as 'male' | 'female',
        heightInches,
        weightPounds: parseFloat(phase2.weight),
        diagAlcoholAbuse: hasCondition(diagCond.Alcohol),
        drugAlcoholTreatment: !!phase2.treatment,
        drugAlcoholTreatmentDate: getStandardizedDateString(phase2.treatmentWhen),
        diagDrugAbuse: hasCondition(diagCond.Drug),
        alcoDrugFreeYears: phase2.alcoDrugFreeYears ? Number(phase2.alcoDrugFreeYears) : null,
        diagMentalDisorder: hasCondition(diagCond.PsychDisorder),
        mentalDisorderHospitalization: !!phase2.diagPsychLastHosp,
        mentalDisorderDiagnoses: phase2.diagPsychCondition || null,
        mentalDisorderMedCount: (phase2.diagPsychMedication as MedCount) || null,
        behaviorDuiRecklessDriving: hasBehavior(anyCond.DUI),
        behaviorMovingViolation: hasBehavior(anyCond.MovingViolations),
        movingViolationsCount3Years: phase2.movingViolationsHowMany ? Number(phase2.movingViolationsHowMany) : null,
        duiDate: getStandardizedDateString(phase2.DUIWhen),
        behaviorSuspendedLicense: hasBehavior(anyCond.SuspendedLicense),
        suspendedLicenseDate: getStandardizedDateString(phase2.suspendedLicenseWhen),
        suspendedLicenseStill: !!phase2.suspendedLicenseStill,
        behaviorDrugAlcoholAbuse: hasBehavior(anyCond.AlcoholOrDrug),
        usedMarijuana: phase2.usedMarijuana,
        marijuanaLastUseDate: getStandardizedDateString(phase2.usedMarijuanaLastDate),
        marijuanaFrequencyOfUse: phase2.usedMarijuanaFrequency,
        asthma: hasCondition(diagCond.Asthma),
        asthmaHospitalization5Years: getBooleanOrNull(phase2.diagAsthmaLastHosp as any),
        diabetes,
        diabetesType,
        diabetesLastA1C: phase2.diagDiabetesA1C ? Number(phase2.diagDiabetesA1C) : null,
        diabetesTreatment: phase2.diagDiabetesTreatments || null,
        sleepApnea: hasCondition(diagCond.SleepApnea),
        sleepApneaCpap: getBooleanOrNull(phase2.diagSleepCPAP as any),
        sleepApneaLevel: (phase2.diagSleepLevel as SleepApneaLevel) || null,
        // TODO remove after verifying not in any rules
        sleeApneaLevel: (phase2.diagSleepLevel as SleepApneaLevel) || null,
        stroke: hasCondition(diagCond.Stroke),
        lastStrokeDate: getStandardizedDateString(phase2.diagStrokeWhen),
        highCholesterol: hasCondition(diagCond.HighCholesterol),
        latestCholesterolHdlRatio: phase2.diagHighCholHDL || null,
        highBloodPressure: hasCondition(diagCond.BloodPressure),
        lastLowBloodPressure: phase2.diagBloodPressLow,
        lastHighBloodPressure: phase2.diagBloodPressHigh,
        bloodPressureMedCount: (phase2.diagBloodPressMedication as MedCount) || null,
        heartAttack: hasCondition(diagCond.HeartAttack),
        lastHeartAttackDate: getStandardizedDateString(phase2.diagHeartAttackWhen),
        cancer: hasCondition(diagCond.Cancer),
        cancerBasalCell: getBooleanOrNull(phase2.diagCancerBasalCell as any),
        cancerLastTreatmentDate: getStandardizedDateString(phase2.diagCancerWhen),
        otherSeriousCondition: phase2.otherConditionDescribe,
        bankruptcy: hasBehavior(anyCond.Bankruptcy),
        bankruptcyChapter: (phase2.bankruptcyChapter as BankruptcyChapter) || null,
        bankruptcyYear: phase2.bankruptcyYear ? Number(phase2.bankruptcyYear) : null,
        bankruptcyIsOpen: !!phase2.bankruptcyIsOpen,
        hivAids: phase2.aids,
        currentlyEmployed: phase1.employmentStatus === 'employed',
        disability: phase1.unemploymentReasonType == 'On Disability',
        preferredInvestmentRisk: phase1.investmentRisk || null,
        investmentGoals: <InvestmentGoal[]>phase1.features,
        preferredMonthlySpend: phase1.comfortableAmount,
        annualHouseholdIncomeRange: phase1.annualHouseholdIncome || null,
        annualHouseholdIncomeNumber: Number(phase1.annualHouseholdIncomeText),
        campaign: utm.campaign,
        utmSource: utm.source,
        utmMedium: utm.medium,
        utmOrigin: utm.origin,
        utmTerm: utm.term,
        utmContent: utm.content,
        utmAdgroup: utm.adgroup,
        wtStartTime: userData.wtStartTime,
        wtLeadFormTime: userData.wtLeadFormTime,
        tuneId: partners.tuneId,
        offerId: partners.offerId,
        offerName: partners.offerName,
        affiliateId: partners.affiliateId,
        source: partners.source,
        consentAuthUrl: userData.consentAuthUrl,
    };

    // TODO: Remove this code when ENG-1197 is done. Right now this adds dummy data so that the recommendation engine,
    //  quoting and approval can return valid results.
    if (phase1.amplifyVulPrequalify) {
        completeUserData = merge(
            {
                stateId: 4,
                zip: '85902',
                dateOfBirth: '1981-02-01T06:00:00.000Z',
                usedTobacco: false,
                tobaccoLastUseDate: null,
                gender: 'male',
                heightInches: 173,
                weightPounds: null,
                diagAlcoholAbuse: false,
                drugAlcoholTreatment: false,
                drugAlcoholTreatmentDate: null,
                diagDrugAbuse: false,
                alcoDrugFreeYears: null,
                diagMentalDisorder: false,
                mentalDisorderHospitalization: false,
                mentalDisorderDiagnoses: [],
                mentalDisorderMedCount: null,
                behaviorDuiRecklessDriving: false,
                behaviorMovingViolation: false,
                movingViolationsCount3Years: null,
                duiDate: null,
                behaviorSuspendedLicense: false,
                suspendedLicenseDate: null,
                suspendedLicenseStill: false,
                behaviorDrugAlcoholAbuse: false,
                usedMarijuana: false,
                marijuanaLastUseDate: null,
                marijuanaFrequencyOfUse: null,
                asthma: false,
                asthmaHospitalization5Years: null,
                diabetes: false,
                diabetesType: null,
                diabetesLastA1C: null,
                diabetesTreatment: null,
                sleepApnea: false,
                sleepApneaCpap: null,
                sleeApneaLevel: null,
                stroke: false,
                lastStrokeDate: null,
                highCholesterol: false,
                latestCholesterolHdlRatio: null,
                highBloodPressure: false,
                lastLowBloodPressure: '',
                lastHighBloodPressure: '',
                bloodPressureMedCount: null,
                heartAttack: false,
                lastHeartAttackDate: null,
                cancer: false,
                cancerBasalCell: null,
                cancerLastTreatmentDate: null,
                otherSeriousCondition: '',
                bankruptcy: false,
                bankruptcyChapter: null,
                bankruptcyYear: null,
                bankruptcyIsOpen: false,
                hivAids: false,
                currentlyEmployed: true,
                disability: false,
                preferredInvestmentRisk: 'high',
                investmentGoals: ['savings'],
                preferredMonthlySpend: 500,
                annualHouseholdIncomeRange: 5,
                annualHouseholdIncomeNumber: 12683957,
                campaign: '',
                utmSource: '',
                utmMedium: '',
                utmOrigin: '',
                utmTerm: '',
                utmContent: '',
            },
            completeUserData,
        );
    }

    const userIdData: UserIdData = {
        id: userData.uuid,
        phoneNumber: '+1 ' + userData.phone,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
    };

    return {userData: completeUserData, userIdData};
};

export const mapAvailableRidersToLegacyState = (riders: RiderInfo | null): string[] => {
    return [riders?.chronic && 'Chronic', riders?.critical && 'Critical'];
};

function getStandardizedDateString(dateString: string): string {
    if (!dateString) {
        return null;
    }
    return dayjs(dateString, 'MM/YYYY').format('YYYY-MM-DD');
}

function getBooleanOrNull(b: '' | true | false) {
    if (b === '' || b == null) {
        return null;
    }
    return b;
}
