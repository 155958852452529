import {makeAutoObservable, runInAction} from 'mobx';
import {serializable} from '../sync/serialization';

class ComponentsStore {
    @serializable()
    consentActiveDrawer: boolean | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    updateField(fieldName: string, val: unknown) {
        runInAction(() => (this[fieldName] = val));
    }
}

export default ComponentsStore;
